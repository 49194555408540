import React, { useState } from 'react';
import ClassNames from 'classnames';

import { Dialog, Heading, Divider, Content, ButtonGroup, Button, TextField } from '@adobe/react-spectrum';

import { loginUser } from '../../../../util/http/http';

import styles from './LoginDialog.module.scss';

export default ({ close, onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Dialog>
      <Heading>Log in to your account</Heading>
      <Divider />
      <Content>
        <TextField
          label="Username"
          placeholder="Username"
          value={username}
          type="text"
          onChange={setUsername}
          UNSAFE_className={ClassNames(styles.username, styles.section)}
        />
        <TextField
          label="Password"
          placeholder="Password"
          value={password}
          type="password"
          onChange={setPassword}
          UNSAFE_className={ClassNames(styles.section)}
        />
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close}>
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            loginUser({ username, password }).then((data) => {
              close();
              onLogin(data.data);
            });
          }}
          autoFocus
        >
          Log in
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
