import React, { useState } from 'react';

import ClassNames from 'classnames';

import { ToggleButton } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';

import NavigationDrawer from './navDrawer/navDrawer';

import styles from './navigation.module.scss';

export default function Navigation(props) {
  const [drawerStatus, setDrawerStatus] = useState(false);

  return (
    <div className={styles.navContainer}>
      <div className={styles.navigation}>
        <div className={styles.navContent}>
          <div className={styles.navDrawerButton}>
            <ToggleButton isSelected={drawerStatus} onPress={() => setDrawerStatus(!drawerStatus)}>
              <ShowMenu />
            </ToggleButton>
          </div>
          <div className={styles.navTitle}>Love Towing - Logan</div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={ClassNames(styles.leftRail, drawerStatus ? styles.leftRailOpen : '')}>
          <NavigationDrawer setDrawerStatus={setDrawerStatus} />
        </div>

        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
}
