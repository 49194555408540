import axios from 'axios';

const requestCache = {};

const cachedGet = (url) => {
  if (!requestCache[url]) requestCache[url] = axios.get(url);

  return requestCache[url];
};

export function removeTextCache(id) {
  delete requestCache['/texts/' + id];
}

export function getText(id) {
  return cachedGet('/texts/' + id);
}

export function getTexts() {
  return axios.get('/textedit/all');
}

export function saveText(edit) {
  return axios.post('/textedit/save/' + edit.page, edit);
}

export function getUser() {
  return axios.get('/user');
}

export function updateUser(user) {
  return axios.put('/user/update', user);
}

export function logoutUser() {
  return axios.get('/user/logout');
}

export function loginUser(user) {
  return axios.post('/user/login', user);
}

export function getRegulations() {
  return cachedGet('/regulations');
}

export function getTemporaryProperties() {
  return cachedGet('/temporary/properties');
}

export function submitTemporary(permit) {
  return axios.post('/temporary/create', permit);
}

export function getVisitorProperties() {
  return cachedGet('/visitor/properties');
}

export function submitVisitor(permit) {
  return axios.post('/visitor/create', permit);
}

export function getActivatingPermit(permit, phone) {
  return axios.get('/activate/needsActivating/' + permit + '/' + phone);
}

export function activatePermit(permit) {
  return axios.put('/activate', permit);
}

export function getProperties() {
  return axios.get('/properties');
}

export function saveProperty(property) {
  if (property.rev) {
    return axios.put('/properties/edit', property);
  } else {
    return axios.post('/properties/add', property);
  }
}

export function deleteProperty(property) {
  return axios.delete(`/properties/delete/${property._id}/${property._rev}`);
}

export function resetDatabase() {
  return axios.get('/archive/reset');
}

export function getAPIKeys() {
  return axios.get('/apikeys');
}

export function deleteAPIKey(id, rev) {
  return axios.delete(`/apiKeys/${id}/${rev}`);
}

export function getScanningPlatesAndProperties() {
  return axios.get('/scanning/plates');
}

/**
 * Management properties
 */

export function getAllProperties() {
  return axios.get('/management/allProperties');
}

export function getApplicableProperties() {
  return axios.get('/management/applicableProperties');
}

/**
 * Users management page
 */
export function getUsers() {
  return axios.get('/users/');
}

export function addUser(user) {
  return axios.post('/users/add', user);
}

export function editUser(user) {
  return axios.put('/users/edit', user);
}

export function deleteUser(id, rev) {
  return axios.delete('/users/delete/' + id + '/' + rev);
}

export function forceResetPassword(id) {
  return axios.get('/users/forceResetPassword/' + id);
}
/**
 * Permit management page
 */
export function getDetailedInfo(id) {
  return axios.get('/permits/getpermits/' + id);
}

export function addPermit(permit) {
  return axios.post('/permits/add', permit);
}

export function editPermit(permit) {
  return axios.put('/permits/edit', permit);
}

export function voidPermit(id) {
  return axios.get('/permits/void/' + id);
}

export function reissuePermit(id, newPermit) {
  return axios.post('/permits/reissue/' + id, newPermit);
}

export function deletePermit(id, rev) {
  return axios.delete('/permits/delete/' + id + '/' + rev);
}

export function exportCSV(id) {
  window.open('/permits/exportcsv/' + id);
}

/**
 * Temp permit management page
 */
export function getVisitorPermits(id) {
  return axios.get('/temporary/location/' + id);
}

export function updateVisitorPermit(permit) {
  return axios.put('/temporary/update', permit);
}
