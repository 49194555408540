import React from 'react';

import { AlertDialog } from '@adobe/react-spectrum';

import { logoutUser } from '../../../../util/http/http';

export default ({ onLogout }) => {
  return (
    <AlertDialog
      variant="warning"
      title="Log out"
      primaryActionLabel="Log out"
      cancelLabel="Cancel"
      autoFocusButton="primary"
      onPrimaryAction={() => {
        logoutUser();
        onLogout({});
      }}
    >
      Are you sure you want to log out?
    </AlertDialog>
  );
};
