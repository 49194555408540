import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom/';

import ReactGA from 'react-ga';
import { Item, ListBox, Section, Text, DialogContainer } from '@adobe/react-spectrum';
import Login from '@spectrum-icons/workflow/Login';

import { getUser, logoutUser } from '../../../util/http/http';
import { views, adminViews } from '../../../views/views';
import LoginDialog from './loginlogout/LoginDialog';
import LogoutDialog from './loginlogout/LogoutDialog';

import styles from './navDrawer.module.scss';

export default function NavigationDrawer({ setDrawerStatus, ...props }) {
  const [user, setUser] = useState({});
  const [showLoginLogout, setShowLoginLogout] = useState(false);

  const navigate = useNavigate();
  const doNavigate = useCallback((route) => {
    if (route === '::login::' || route === '::logout::') {
      setShowLoginLogout(true);
      setDrawerStatus(false);
    } else {
      navigate(route);
      setDrawerStatus(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let result = await getUser();
        setUser(result.data);

        window.user = result.data;
      } catch (e) {
        setUser({});

        window.user = {};
      }
    })();
  }, []);

  useEffect(() => {
    if (user.username) {
      ReactGA.set({ username: user.username });
      ReactGA.event({ category: 'user', action: 'login', label: user.username });
    } else {
      ReactGA.set({ username: null });
    }
  }, [user]);

  const logout = () => {
    setUser({});
    doNavigate('/');
  };

  return (
    <>
      <ListBox
        selectionMode="single"
        selectedKeys={[]}
        onSelectionChange={(selection) => doNavigate(selection.values().next().value)}
        aria-label="Menu"
      >
        <Section title="Pages">
          {views.map((view) => {
            let Icon = view.icon;

            return (
              <Item key={view.route} textValue={`${view.name} -- ${view.description}`}>
                <Icon size="S" />
                <Text>{view.name}</Text>
                <Text slot="description">{view.description}</Text>
              </Item>
            );
          })}
        </Section>
        {user.username && (
          <Section title="Admin">
            {adminViews.map((view) => {
              if (!view.accessLevels.includes(user.accessLevel)) {
                return null;
              }
              let Icon = view.icon;

              return (
                <Item key={view.route} textValue={`${view.name} -- ${view.description}`}>
                  <Icon size="S" />
                  <Text>{view.name}</Text>
                  <Text slot="description">{view.description}</Text>
                </Item>
              );
            })}
          </Section>
        )}
        <Section title="">
          {!user.username && (
            <Item key="::login::" textValue="Login">
              <Login size="S" />
              <Text>Log in</Text>
              <Text slot="description">Log in to your account</Text>
            </Item>
          )}
          {user.username && (
            <Item key="::logout::" textValue="Logout">
              <Login size="S" />
              <Text>Log out</Text>
              <Text slot="description">Log out of your account</Text>
            </Item>
          )}
        </Section>
      </ListBox>
      <DialogContainer onDismiss={() => setShowLoginLogout(false)} {...props}>
        {showLoginLogout && !user.username && <LoginDialog onLogin={setUser} close={() => setShowLoginLogout(false)} />}
        {showLoginLogout && user.username && <LogoutDialog onLogout={logout} close={() => setShowLoginLogout(false)} />}
      </DialogContainer>
    </>
  );
}
