import { lazy } from 'react';

import Home from '@spectrum-icons/workflow/Home';
import Money from '@spectrum-icons/workflow/Money';
import Document from '@spectrum-icons/workflow/Document';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import Visit from '@spectrum-icons/workflow/Visit';

import Car from '@spectrum-icons/workflow/Car';
import Building from '@spectrum-icons/workflow/Building';
import Card from '@spectrum-icons/workflow/Card';
import CreditCard from '@spectrum-icons/workflow/CreditCard';
import BulkEditUsers from '@spectrum-icons/workflow/BulkEditUsers';
import User from '@spectrum-icons/workflow/User';
import MovieCamera from '@spectrum-icons/workflow/MovieCamera';
import Data from '@spectrum-icons/workflow/Data';
import TextParagraph from '@spectrum-icons/workflow/TextParagraph';

export const views = [
  {
    id: 'main',
    name: 'Welcome',
    description: 'Home page',
    route: '/',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "home" */ './generic/generic'))),
    componentProps: {},
    icon: Home,
  },
  {
    id: 'regulations',
    name: 'Regulations',
    description: 'Learn about the rules at a property',
    route: '/regulations',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "regulations" */ './tenant/regulations/regulations')),
    ),
    componentProps: {},
    icon: Document,
  },
  {
    id: 'temporary',
    name: 'Temporary Permits',
    description: 'Create a temporary permit',
    route: '/temporary',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "temporary" */ './tenant/temporary/temporary'))),
    componentProps: {},
    icon: Car,
  },
  {
    id: 'visitors',
    name: 'Visitor Permits',
    description: 'Create a visitor permit',
    route: '/visitor',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "visitors" */ './tenant/visitors/visitors'))),
    componentProps: {},
    icon: Visit,
  },
  {
    id: 'fees',
    name: 'Fee Schedule',
    description: 'Learn about standard fees',
    route: '/fees',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "fees" */ './generic/generic'))),
    componentProps: {},
    icon: Money,
  },
  {
    id: 'activate',
    name: 'Activate/Edit Permit',
    description: 'Activate your permit, or update your permit information',
    route: '/activate',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "activate" */ './tenant/activate/activate'))),
    componentProps: {},
    icon: CheckmarkCircle,
  },
];

export const hiddenViews = [
  {
    id: 'privacy',
    name: 'Privacy policy',
    route: '/privacy',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "privacy" */ './generic/generic'))),
    componentProps: {},
  },
  {
    id: 'support',
    name: 'Support',
    route: '/support',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "support" */ './generic/generic'))),
    componentProps: {},
  },
];

export const adminViews = [
  {
    id: 'management.permits',
    name: 'Permit management',
    description: 'View and manage permits for properties',
    route: '/management/permits',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "permitmanagement" */ './management/permit/permit'))),
    componentProps: {},
    icon: Car,
    accessLevels: [1, 2, 3],
  },
  {
    id: 'management.properties',
    name: 'Property management',
    description: 'View and manage properties',
    route: '/management/properties',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "propertymanagement" */ './management/property/property')),
    ),
    componentProps: {},
    icon: Building,
    accessLevels: [3],
  },
  {
    id: 'management.temporary',
    name: 'Temp permit management',
    description: 'View and managetemporary permits',
    route: '/management/temporary',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "temporarymanagement" */ './management/temporary/temporary')),
    ),
    componentProps: {},
    icon: Card,
    accessLevels: [1, 2, 3],
  },
  {
    id: 'management.history',
    name: 'Enforcement history',
    description: 'View property enforcement history',
    route: '/management/history',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "historymanagement" */ './management/history/history')),
    ),
    componentProps: {},
    icon: CreditCard,
    accessLevels: [1],
  },
  {
    id: 'management.historyPrivelaged',
    name: 'Enforcement',
    description: 'View and manage enforcement history',
    route: '/management/enforcement',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "enforcementmanagement" */ './management/enforcement/enforcement')),
    ),
    componentProps: {},
    icon: CreditCard,
    accessLevels: [2, 3],
  },
  {
    id: 'management.users',
    name: 'User management',
    description: 'View and manage users and access',
    route: '/management/users',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "usersmanagement" */ './management/users/users'))),
    componentProps: {},
    icon: BulkEditUsers,
    accessLevels: [3],
  },
  {
    id: 'management.textedit',
    name: 'Edit texts',
    description: 'View and edit texts for the site',
    route: '/management/texts',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "textsmanagement" */ './management/texts/texts'))),
    componentProps: {},
    icon: TextParagraph,
    accessLevels: [3],
  },
  {
    id: 'management.archive',
    name: 'Reset database',
    description: 'Delete database and start over',
    route: '/management/database',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "databasemanagement" */ './management/database/database')),
    ),
    componentProps: {},
    icon: Data,
    accessLevels: [3],
  },
  {
    id: 'management.account',
    name: 'Account management',
    description: 'View my account or change password',
    route: '/management/account',
    routeParams: { exact: true },
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "accountmanagement" */ './management/account/account')),
    ),
    componentProps: {},
    icon: User,
    accessLevels: [1, 2, 3],
  },
  {
    id: 'management.api',
    name: 'API key management',
    description: 'Manage API keys',
    route: '/management/api',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "accountmanagement" */ './management/api/api'))),
    componentProps: {},
    icon: MovieCamera,
    accessLevels: [3],
  },
  {
    id: 'management.scan',
    name: 'Scan plates',
    description: 'Scan plates for enforcement',
    route: '/management/scan',
    routeParams: { exact: true },
    component: lazy(() => retry(() => import(/* webpackChunkName: "accountmanagement" */ './management/scan/scan'))),
    componentProps: {},
    icon: MovieCamera,
    accessLevels: [2, 3],
  },
];

export const allViews = views.concat(hiddenViews).concat(adminViews);

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft <= 1) {
            return reject(error);
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
