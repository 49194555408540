import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { ToastProvider } from 'react-toast-notifications';

import { Provider, defaultTheme } from '@adobe/react-spectrum';
import ReactGA from 'react-ga';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import { allViews } from './views/views';

import Navigation from './viewComponents/navigation/navigation';

import { content } from './app.module.scss';
import './app.scss';

const trackingId = 'UA-62244026-1';
ReactGA.initialize(trackingId);

export default function App() {
  const [smallScreen, setSmallScreen] = useState(window.matchMedia('(max-width: 750px)').matches);

  window.onresize = () => {
    let { matches } = window.matchMedia('(max-width: 750px)');

    setSmallScreen(matches);
  };

  const onRouteChange = useCallback((view, props) => {
    document.title = view.name;
    ReactGA.pageview(view.route);
  });

  useEffect(() => {
    ReactGA.initialize(trackingId);
  });

  return (
    <Provider theme={defaultTheme}>
      <ToastProvider placement="bottom-center">
        <Router>
          <Navigation>
            <Routes>
              {allViews
                .filter((view) => view.route)
                .map((view) => {
                  let RouteComponent = view.component;

                  return (
                    <Route
                      key={view.route}
                      path={view.route}
                      element={
                        <Suspense fallback={<div>Loading</div>}>
                          <RouteComponent
                            path={view.route}
                            id={view.id}
                            name={view.name}
                            {...(view.componentProps || {})}
                            accessLevels={view.accessLevels}
                            smallScreen={smallScreen}
                          />
                        </Suspense>
                      }
                    />
                  );
                })}
              {/* {allViews
                .filter((view) => view.route)
                .map((view) => {
                  let RouteComponent = view.component;

                  return (
                    <Suspense key={view.route} path={view.route} fallback={<div>Loading</div>}>
                      <RouteComponent
                        // {...props}
                        path={view.route}
                        id={view.id}
                        name={view.name}
                        {...(view.componentProps || {})}
                        // params={props.match.params}
                        // {...props.match.params}
                        accessLevels={view.accessLevels}
                        smallScreen={smallScreen}
                      />
                    </Suspense>
                  );
                  /* <Route
                      key={view.route}
                      path={view.route}
                      {...view.routeParams}
                      component={(props) => {
                        let RouteComponent = view.component;
                        onRouteChange(view, props);

                        return (
                          <Suspense fallback={<div>Loading</div>}>
                            <RouteComponent
                              {...props}
                              id={view.id}
                              name={view.name}
                              {...(view.componentProps || {})}
                              params={props.match.params}
                              {...props.match.params}
                              accessLevels={view.accessLevels}
                              smallScreen={smallScreen}
                            />
                          </Suspense>
                        );
                      }}
                    /> */}
              )} */}
            </Routes>
          </Navigation>
        </Router>
      </ToastProvider>
    </Provider>
  );
}
